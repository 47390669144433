import React from 'react'

import Text from 'atoms/Text'
import List from 'atoms/List'
import Button from 'atoms/Button'
import ImageNext from 'atoms/ImageNext'
import Container from 'atoms/Container'
import LinkAnchor from 'atoms/LinkAnchor'

import { CLR } from 'styles/theme'
import { handlerLogout } from 'core/logout'
import { useRouter } from 'next/router'
import { getAuth, removeAuth } from 'services/identity.service'
import * as moengage from "services/moengage.service";


const PractitionerSection = ({ className = '', windowWidth, heading, description, buttonText, list }) => {
  const router = useRouter();
  const auth = getAuth();
  return (
    <Container type='static' className={className}>
      <div className='rounded-2xl overflow-hidden relative  w-full h-full lg:aspect-[1280/120] md:max-h-[180px]'>
        {windowWidth < 768
          ? <ImageNext src={'/images/nirogstreet-background-img-mob.jpg'} alt={heading + 'Banner'} layout='fill' objectFit='cover' />
          : <ImageNext src={'/images/nirogstreet-background-img-web.jpg'} alt={heading + 'Banner'} layout='fill' objectFit='cover' objectPosition='left' />
        }

        <div className='p-5 lg:pr-0 lg:py-5 relative z-1 max-w-[880px] flex flex-col md:flex-col md:items-start lg:flex-row lg:items-center  md:ml-0 lg:ml-auto'>

          <div className='md:max-w-[500px] flex flex-col items-center mt-0'>
            <Text tag='h2' className='font-Montserrat text-18-20  md:text-18-24 :mb-1 md:max-w-[520px]' fontWeight={700} color={CLR.white.text} >
              {heading}
            </Text>

            <Text tag='p' className='font-Montserrat md:text-14-20 text-12-16 mt-0 mb-0' fontWeight={500} color={CLR.white.text} >
              {description}
            </Text>

            <List className='grid  grid-cols-1 xxs:grid-cols-3  text-12-16 md:text-14-16 leading-5 mt-4 pl-1 mb-0 gap-1 md:gap-6' classList='flex items-start gap-0.5 text-basic-white' iconType='IconTickThick' list={list} mb='mb-2.5 md:mb-3' iconClass="w-3 md:w-4-5 text-basic-white pt-0.5" />
          </div>
          <div className='md:max-w-[500px] flex flex-col items-center md:ml-15'>
            <LinkAnchor onClick={(e) => {
              e.preventDefault();
              if (auth) {
                moengage.destroySession();
                removeAuth()
                router.push('/practitioner')
              } else {
                router.push('/practitioner')
              }
            }} href='/'>
              <Button size='small' className='md:mx-0 rounded-lg w-[190px] px-0 min-w-[200px]'>{buttonText}</Button>
            </LinkAnchor>
          </div>
        </div>

      </div>
    </Container>
  )
}

export default PractitionerSection